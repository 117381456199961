<template>
  <div class="container item-price-side">
    <div class="row">
      <div class="col-12 mt-3 purchase">
            <h4 class="price">{{ formatCurrency(priceLesson.currency_code, priceLesson.price) }} </h4>
            <div class="item">
              <a href="#selectoption" >{{ $t("lesson.item.select_option") }} </a>
            </div>
            <button
                class="text-center button-purchase"
              >
              {{ $t("lesson.detail.reservation_screen") }}
            </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    priceLesson() {
      return this.data.price_lesson;
    },
  },
}
</script>

<style></style>
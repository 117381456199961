<template>
    <div>
        <div class="main">
            <Breadcrumb :breadcrumb="breadcrumb" />
            <div class="detail-lesson">
                <div class="submit-lesson lesson-prev mb-5">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                                {{msg}}
                            </div>
                        </div>
                        <div class="col-12 text-center mb-3">
                            <h6 class="title">
                                {{ $t('lesson.form.register.title') }}
                            </h6>
                        </div>
                        <div class="col-6 text-center">
                            <button class="btn btn-cancel" @click="cancelRegister">
                                {{ $t('lesson.form.register.cancel') }}
                            </button>
                        </div>
                        <div class="col-6 text-center">
                            <button class="btn btn-submit" @click="submitRegister">
                                {{ $t('lesson.form.register.submit') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-100" id="table">
                    <div class="tr">
                        <div class="td">
                            <div class="side-left" v-if="data">
                                <div>
                                    <div class="info title" v-if="data.level">
                                        {{ $t(`lesson.${data.level}`) }}
                                    </div>
                                    <h2 class="title">{{ data.title }}</h2>
                                </div>
                                <div class="favorite">
                                    <Favorite />
                                </div>
                                <div>
                                    <LessonIntro :text="data.introduction" />
                                </div>
                                <div>
                                    <Thumbnail :thumbnail="data.preview.thumbnail" :image_1="data.preview.image_1"
                                        :image_2="data.preview.image_2" />
                                </div>
                                <div>
                                    <Price :data="data" />
                                </div>
                            </div>
                        </div>
                        <div class="td">
                            <div class="side-right" v-if="data">
                                <SlotsAside :data="data" />
                                <PriceAside :data="data" />
                                <TeacherProfile :data="data" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MetaTags 
            title="出品リスト｜ResMom相談online"
            description="リセマム相談オンラインの出品リストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Favorite from "./components/section/favorite.vue";
import LessonIntro from "./components/section/lesson-intro.vue";
import Thumbnail from "./components/section/thumbnail.vue";
import Price from "./components/section/price.vue";
import PriceAside from "./components/aside/price.vue";
import TeacherProfile from "./components/aside/teacher-profile.vue";
import SlotsAside from "./components/aside/slots.vue";

export default {
    components: {
    Favorite,
    LessonIntro,
    Thumbnail,
    Price,
    PriceAside,
    TeacherProfile,
    SlotsAside,
},
    data() {
        return {
            breadcrumb: [
                {
                    "link": "/my-page",
                    "title": "マイページ "
                },
                {
                    "link": "/my-page/lesson",
                    "title": "出品リスト "
                },
                {
                    "link": "#",
                    "title": "相談登録"
                },
                {
                    "link": "#",
                    "title": "プレビュー"
                },
            ],
            data: null,
            isSaving: false,
            msgType: "danger",
            msg: "",
            previewId: ""
        }
    },
    created() {
        this.previewId = this.$route.params.id;
        this.loadPreview();
    },
    methods: {
        ...mapActions("lesson", {
            getPreviewLesson: "getPreviewLesson",
            updateLesson: "updateLesson",
            createLesson: "createLesson"
        }),
        loadPreview() {
            this.getPreviewLesson({
                params: {
                    id: this.previewId
                },
                cb: (response) => {
                    this.data = response.data;
                    this.price.total = response.data.price_lesson.price;
                }
            })
        },
        submitRegister() {
            const payload = this.data.payload;
            if(payload.action_type=="edit"){
                this.editLesson(payload);
            }else {
                this.storeLesson(payload);
            }
        },
        cancelRegister() {
            if (this.isSaving) return;
            if (this.data.payload.action_type == "edit") {
                this.$router.push(`/my-page/lesson-edit/${this.data.id}?previewId=${this.previewId}`);
            } else {
                this.$router.push(`/my-page/lesson-create?previewId=${this.previewId}`);
            }
        },
        editLesson(payload){
            if(this.isSaving)return;

            const items = payload.optionalItems;
            let invalid = false;
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if(!item.name){
                    invalid = true;
                    this.msg = this.$t("lesson.form.option_name_required");
                    break;
                }else if(item.price < 100){
                    invalid = true;
                    this.msg = this.$t("lesson.form.price_required");
                    break;
                }
            }
            if(invalid){
                return;
            }
            
            this.$isLoading(true);
            this.isSaving = true;
            this.updateLesson({
                data: payload, 
                id: payload.id,
                cb: (response) => {
                    if(response.data && 
                        response.meta && 
                        response.meta.code == 200){
                            if(response.data.purchased == true){
                                this.msg = this.$t("lesson.item_ready_puchased");
                                this.msgType = "danger";
                            }else{
                                this.msg = this.$t("lesson.created_success");
                                this.msgType = "success";
                                setTimeout(() => {
                                    this.$router.push("/my-page/lesson");
                                }, 1000);
                            }
                    }else{
                        if(response.data && response.data.errors){
                            let { errors } = response.data;
                            let _error = Object.values(errors)[0];
                            if(typeof _error  == "object")_error = _error[0]
                            this.msg = _error;
                        }else if(response.data && response.data.message){
                            this.msg = response.data.message.substr(0, 100);
                        }else{
                            this.msg = this.$t("lesson.created_failed");
                        }
                        this.msgType = "danger"
                    }
                    this.isSaving = false;
                    this.$isLoading(false);
                }
            });
        },
        storeLesson(payload){
            if(this.isSaving)return;

            const items = payload.optionalItems;
            let invalid = false;
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if(!item.name){
                    invalid = true;
                    this.msg = this.$t("lesson.form.option_name_required");
                    break;
                }else if(item.price < 100){
                    invalid = true;
                    this.msg = this.$t("lesson.form.price_required");
                    break;
                }
            }
            if(invalid){
                return;
            }
            
            this.isSaving = true;
            this.$isLoading(true);
            this.createLesson({
                data: payload, 
                cb: (response) => {
                    if(response.data && 
                        response.meta && 
                        response.meta.code == 200){
                            this.msg = this.$t("lesson.created_success");
                            this.msgType = "success";
                            setTimeout(() => {
                              this.$router.push("/my-page/lesson");
                            }, 1000);
                    }else{
                        if(response.data && response.data.errors){
                            let { errors } = response.data;
                            let _error = Object.values(errors)[0];
                            if(typeof _error  == "object")_error = _error[0]
                            this.msg = _error;
                        }else if(response.data && response.data.message){
                            this.msg = response.data.message.substring(0, 100);
                        }else{
                            this.msg = this.$t("lesson.created_failed");
                        }
                        this.msgType = "danger"
                    }
                    this.isSaving = false;
                    this.$isLoading(false);
                }
            })
        },
    },
}
</script>

<style></style>
<template>
  <div class="teacher-profile">
    <h3 class="title">
      {{ $t("lesson.detail.instructor") }}
    </h3>
    <div class="content">
      <img :src="teacherInfo.profile_image" />
      <h3 class="name">
        {{ teacherInfo.name }}
      </h3>
      <p class="text-pre-line descr">
        {{ teacherInfo.introduction }}
      </p>
      <button class="view-profile">
        {{ $t("lesson.detail.teacher_profile") }} <i class="fa fa-2x fa-angle-right" aria-hidden="true"></i>
      </button>
    </div>
    <button class="button-inquiry" style="min-width: 150px">
      {{ $t("lesson.detail.contact_instructor") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  computed: {
    teacherInfo(){
      return this.data.teacher_info;
    },
  }
};
</script>

<style></style>
<template>
  <div>
    <p class="introduction">
      {{text}}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style>
</style>
<template>
  <div class="d-flex justify-content-between">
    <div>
      <div class="star">
         <star-rating
            :rating="0"
            :star-size="22"
            :show-rating="false"
            :read-only="true"
          />
        0
        (0)
      </div>
    </div>
    <div class="btn-fav" role="button" >
        <i class="fa fa-heart-o" aria-hidden="true"></i>
        {{ $t("lesson.detail.favorite") }}0{{ $t("lesson.detail.people") }}
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.bi-heart-fill {
  color: red;
  cursor: pointer;
}
</style>
<template>
  <div class="container item-price-side mb-2">
    <div class="row">
      <div class="col-12">
            <h4 class="price"><b>受付中／枠数 </b></h4>
            <div class="item">
                <h4 class="price"><b>0／{{ data.number_of_slots }}</b></h4>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
}
</script>

<style></style>